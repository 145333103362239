<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images :animation="true"/>

		<div class="cols2_2-8">
			<div v-if="imgs">
				<img v-for="img in imgs" :src="$store.state.server + '/' + img.path" :alt="img.alt_text" class="img1"  :title="img.title"  @click="showLighBoxMulti">
			</div>
			
			
			<div> <!-- right -->
				<div class="hl1">
					<h2 class="cap">Bepflanzung</h2>
				</div>
					<p class="cap" style="margin: 2rem 0"><span class="hgl1">Pflanzen</span> bilden Räume, bringen Struktur und Farbe, Duft und Bewegung in den Garten und locken Insekten und kleine Tiere an. Durch die Pflanzenwelt im eigenen Garten erlebt man den Lauf der Jahreszeiten, freut sich über das erste Grün im Frühling und bewundert die intensiven Farben des Herbstlaubs.</p>
					<p class="cap" style="margin: 2.4rem 0">Die Pflanzen aufeinander abzustimmen, auf ihre Größe, Form und Verwendung zu achten, sowie ihre Veränderung im Jahresablauf im Blickfeld zu behalten, ist die komplexe Aufgabe des Gestalters.</p>
					<div class="f2 cap bor1">Wir beraten Sie bei der Auswahl Ihrer Bäume, Sträucher und Stauden, planen und gestalten Ihre Pflanzbeete und liefern und setzen Ihre Pflanzen vor Ort.</div>
					<div class="cap"></div>

					<div class="hl1">
						<h2 class="cap" style="margin: -.4rem 0">Rasenanlage </h2>
					</div>
					<div class="f2 cap kgor">Kein Garten ohne Rasen!</div>
					<div>

						<div class="bor1">
							<div>Wir erledigen:</div>
							<div >
								<ul>
									<li><span class="hgl1">alle notwendigen Erdarbeiten</span> <br>(Liefern und Aufbringen von Humus, Grob-und Feinplanie)</li>
									<li><span class="hgl1">Ansäen des Rasens</span></li>
									<li><span class="hgl1">Verlegung von Rollrasen</span></li>
								</ul>
							</div>
						</div>
					</div>


				</div>
			</div>




	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'GG_Bepflanzung',
		components: {
			// dialogComp,
			lightBox
		},
		mounted(){
			this.getImgs();

		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,
			}
		}, // end data
		methods: {
			getImgs(){

				let self = this;
				let url = this.$store.state.server + '/api/getImagesIndex.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'gartengestaltung-bepflanzung' },
				})
				.then(function (resp) {


					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},
			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				    for (let item of coll) {
				    i++;

            if (item.src === selSrc) {
            	this.actIndex = i;
            }
            
				    this.images.push({src: item.src, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
        }
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
	.container {
		height: 100%;
	}
	.img1 {
		margin-bottom: 2rem;
				cursor: pointer;
	}

.kgor {
	background: var(--c12);
color: white;
padding: 0.5rem;
border-radius: 4px;
font-weight: bold;
}



</style>
